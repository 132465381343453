<template>
  <b-container class="bg-white" v-if="permission.read">
    <b-row v-if="!loadingEntities && show.all" class="mb-4">
      <b-col>
        <h2 class="mb-4">Entities <b-button @click="entityAdd" v-if="permission.add">add</b-button></h2>
        <b-input-group class="mb-5">
          <b-form-input v-model="searchFilter" placeholder="Search......" />
        </b-input-group>
        <div class="mb-3" v-for="entity in entities" :key="'entity-id-' + entity.id">
          <entity :entity="entity">
          </entity>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="loadingEntities" class="mb-4">
      <b-col>
        <b-spinner label="Loading..."></b-spinner>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permission && show.add">
      <b-col>
          <h5>Adding new entitis</h5>
          <b-form-group id="input-name-group" label="Name" label-for="input-name">
            <b-form-input id="input-name" v-model="form.name" placeholder="Name"></b-form-input>
          </b-form-group>
          <b-form-group id="input-description-group" label="Description" label-for="input-description">
            <b-form-textarea> id="input-description" v-model="form.description" placeholder="Description"></b-form-textarea>
          </b-form-group>
          <b-button class="mr-4" v-if="validate" variant="outline-primary" @click="entitySave">Save</b-button>
          <b-button variant="outline-primary" @click="entityAddCancel">Cancel</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import moment from 'moment'
import Entity from '@/components/Entity.vue'

export default {
  name: 'Entities',
  components: {
    Entity
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    entities: function () {
      const searchResults = this.entitiesRaw.filter(item => {
        const term = item.name + ' ' + item.description
        return term.toUpperCase().match(this.searchFilter.toUpperCase())
      })
      return searchResults
    },
    validate: function () {
      let name = false
      if (this.form.name.length > 2) {
        name = true
      }
      return name
    }
  },
  created: async function () {
    this.$stat.log({ page: 'entities', action: 'open entities', model: 'entity' })
    this.formSet()
    this.permission.read = ac.can(this.user.acgroups).readAny('regulation').granted
    this.permission.add = ac.can(this.user.acgroups).createAny('regulation').granted
    this.entitiesLoad()
  },
  data () {
    return {
      form: {},
      loadingEntities: true,
      moment: moment,
      permission: {
        add: false,
        read: false
      },
      entity: null,
      entitiesRaw: [],
      searchFilter: '',
      show: {
        add: false,
        all: true,
        users: false
      }
    }
  },
  methods: {
    formSet: function () {
      this.form = {
        name: '',
        description: ''
      }
    },
    entityAdd: function () {
      this.show.add = true
      this.show.all = false
    },
    entityAddCancel: function () {
      this.show.add = false
      this.show.all = true
      this.formSet()
    },
    entitySave: async function () {
      try {
        const params = {
          body: this.form
        }
        await this.$Amplify.API.put('cosmos', '/standard/entity', params)
        this.formSet()
        this.entitiesLoad()
      } catch (e) {
        this.$logger.warn('entity save error: ', e)
      }
    },
    entitiesLoad: async function () {
      this.loadingEntities = true
      try {
        this.entitiesRaw = await this.$Amplify.API.get('cosmos', '/entities')
        this.loadingEntities = false
      } catch (e) {
        this.$logger.warn('loading entities error ', e)
      }
    }
  }
}
</script>

<style>
</style>
